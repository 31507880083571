export const openingHours = [
    { name: 'Zondag', from: null, until: null },
    { name: 'Maandag', from: '16:00', until: '19:00' },
    { name: 'Dinsdag', from: '09:00', until: '13:00' },
    { name: 'Woensdag', from: '09:00', until: '13:00' },
    { name: 'Donderdag', from: '13:00', until: '17:00' },
    { name: 'Vrijdag', from: null, until: null },
    { name: 'Zaterdag', from: '09:00', until: '13:00' },
]

export const holidays = [
    { name: 'Nieuwjaarsdag', date: '01/01' },
    { name: 'Paasmaandag', date: '04/21' },
    { name: 'Dag van de arbeid', date: '05/01' },
    { name: 'O.H. Hemelvaart', date: '05/29' },
    { name: 'Pinkstermaandag', date: '06/09' },
    { name: 'Nationale feestdag', date: '07/21' },
    { name: 'O.L.V. Hemelvaart', date: '08/15' },
    { name: 'Allerheiligen', date: '11/01' },
    { name: 'Wapenstilstand', date: '11/11' },
    { name: 'Kerstmis', date: '12/25' },
]

export const vacation = [
    { from: '05/12', until: '05/23' },
    { from: '09/20', until: '09/27' },
    { from: '12/23', until: '12/31' },
    { from: '01/01', until: '01/02' },
]

export enum OpeningStatus {
    CLOSED = 'closed',
    OPEN = 'open',
    HOLIDAY = 'holiday',
}